import axios from '@/common/axios'
import qs from 'qs'

export function addAssetRetirement (data) {
  return axios({
    method: 'post',
    url: '/universal/assetRetirement/add',
    data: qs.stringify(data)
  })
}

export function deleteAssetRetirement (id) {
  return axios({
    method: 'delete',
    url: '/universal/assetRetirement/delete/' + id
  })
}

export function updateAssetRetirement (data) {
  return axios({
    method: 'put',
    url: '/universal/assetRetirement/update',
    data: qs.stringify(data)
  })
}

export function selectAssetRetirementInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/assetRetirement/info/' + id
  })
}

export function selectAssetRetirementList (query) {
  return axios({
    method: 'get',
    url: '/universal/assetRetirement/list',
    params: query
  })
}

export function reviewAssetRetirement (data) {
  return axios({
    method: 'put',
    url: '/universal/assetRetirement/review',
    data: qs.stringify(data)
  })
}

export function approvalAssetRetirement (data) {
  return axios({
    method: 'put',
    url: '/universal/assetRetirement/approval',
    data: qs.stringify(data)
  })
}
