<template>
  <div id="assetRetirement">
    <el-dialog
      :title="assetRetirementFormTitle"
      width="680px"
      :visible.sync="assetRetirementDialogVisible"
      :close-on-click-modal="false"
      @close="assetRetirementDialogClose"
    >
      <el-form
        ref="assetRetirementFormRef"
        :model="assetRetirementForm"
        :rules="assetRetirementFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="assetRetirementForm.name"
                placeholder="请输入名称"
                clearable
                :disabled="assetRetirementFormTitle !== '新增固定资产报废申请单'
                  && assetRetirementFormTitle !== '修改固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="assetRetirementForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="assetRetirementFormTitle !== '新增固定资产报废申请单'
                  && assetRetirementFormTitle !== '修改固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="assetRetirementForm.spec"
                placeholder="请输入型号规格"
                clearable
                :disabled="assetRetirementFormTitle !== '新增固定资产报废申请单'
                  && assetRetirementFormTitle !== '修改固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用部门" prop="useDept">
              <el-input
                v-model="assetRetirementForm.useDept"
                placeholder="请输入使用部门"
                clearable
                :disabled="assetRetirementFormTitle !== '新增固定资产报废申请单'
                  && assetRetirementFormTitle !== '修改固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安放地点" prop="location">
              <el-input
                v-model="assetRetirementForm.location"
                placeholder="请输入安放地点"
                clearable
                :disabled="assetRetirementFormTitle !== '新增固定资产报废申请单'
                  && assetRetirementFormTitle !== '修改固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="情况说明" prop="situationExplain">
              <el-input
                v-model="assetRetirementForm.situationExplain"
                placeholder="请输入情况说明"
                clearable
                :disabled="assetRetirementFormTitle !== '新增固定资产报废申请单'
                  && assetRetirementFormTitle !== '修改固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="assetRetirementForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="assetRetirementFormTitle !== '新增固定资产报废申请单'
                  && assetRetirementFormTitle !== '修改固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewerResult">
              <el-radio-group
                v-model="assetRetirementForm.reviewerResult"
                :disabled="assetRetirementFormTitle !== '审核固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approverResult">
              <el-radio-group
                v-model="assetRetirementForm.approverResult"
                :disabled="assetRetirementFormTitle !== '批准固定资产报废申请单'
                  && assetRetirementFormTitle !== '固定资产报废申请单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="assetRetirementDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="assetRetirementFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['ASSET_RETIREMENT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="assetRetirementPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="useDept" label="使用部门" />
      <el-table-column prop="location" label="安放地点" />
      <el-table-column prop="situationExplain" label="情况说明" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applicantDate">{{ scope.row.applicantDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{ scope.row.approverDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approverResult === 1">通过</span>
          <span v-if="scope.row.approverResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ASSET_RETIREMENT_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['ASSET_RETIREMENT_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['ASSET_RETIREMENT_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="checkPermission(['ASSET_RETIREMENT_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="assetRetirementPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addAssetRetirement,
  deleteAssetRetirement,
  updateAssetRetirement,
  selectAssetRetirementInfo,
  selectAssetRetirementList,
  reviewAssetRetirement,
  approvalAssetRetirement
} from '@/api/universal/assetRetirement'

export default {
  data () {
    return {
      assetRetirementDialogVisible: false,
      assetRetirementFormTitle: '',
      assetRetirementForm: {
        id: '',
        name: '',
        serialNumber: '',
        spec: '',
        useDept: '',
        location: '',
        situationExplain: '',
        remarks: '',
        reviewerResult: '',
        approverResult: '',
        taskId: ''
      },
      assetRetirementFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      assetRetirementPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectAssetRetirementList(this.searchForm).then(res => {
      this.assetRetirementPage = res
    })
  },
  methods: {
    assetRetirementDialogClose () {
      this.$refs.assetRetirementFormRef.resetFields()
    },
    assetRetirementFormSubmit () {
      if (this.assetRetirementFormTitle === '固定资产报废申请单详情') {
        this.assetRetirementDialogVisible = false
        return
      }
      this.$refs.assetRetirementFormRef.validate(async valid => {
        if (valid) {
          if (this.assetRetirementFormTitle === '新增固定资产报废申请单') {
            await addAssetRetirement(this.assetRetirementForm)
          } else if (this.assetRetirementFormTitle === '修改固定资产报废申请单') {
            await updateAssetRetirement(this.assetRetirementForm)
          } else if (this.assetRetirementFormTitle === '审核固定资产报废申请单') {
            await reviewAssetRetirement(this.assetRetirementForm)
          } else if (this.assetRetirementFormTitle === '批准固定资产报废申请单') {
            await approvalAssetRetirement(this.assetRetirementForm)
          }
          this.assetRetirementPage = await selectAssetRetirementList(this.searchForm)
          this.assetRetirementDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.assetRetirementFormTitle = '新增固定资产报废申请单'
      this.assetRetirementDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAssetRetirement(row.id)
        if (this.assetRetirementPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.assetRetirementPage = await selectAssetRetirementList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.assetRetirementFormTitle = '修改固定资产报废申请单'
      this.assetRetirementDialogVisible = true
      this.selectAssetRetirementInfoById(row.id)
    },
    handleReview (index, row) {
      this.assetRetirementFormTitle = '审核固定资产报废申请单'
      this.assetRetirementDialogVisible = true
      this.selectAssetRetirementInfoById(row.id)
    },
    handleApproval (index, row) {
      this.assetRetirementFormTitle = '批准固定资产报废申请单'
      this.assetRetirementDialogVisible = true
      this.selectAssetRetirementInfoById(row.id)
    },
    handleInfo (index, row) {
      this.assetRetirementFormTitle = '固定资产报废申请单详情'
      this.assetRetirementDialogVisible = true
      this.selectAssetRetirementInfoById(row.id)
    },
    selectAssetRetirementInfoById (id) {
      selectAssetRetirementInfo(id).then(res => {
        this.assetRetirementForm.id = res.id
        this.assetRetirementForm.name = res.name
        this.assetRetirementForm.serialNumber = res.serialNumber
        this.assetRetirementForm.spec = res.spec
        this.assetRetirementForm.useDept = res.useDept
        this.assetRetirementForm.location = res.location
        this.assetRetirementForm.situationExplain = res.situationExplain
        this.assetRetirementForm.remarks = res.remarks
        this.assetRetirementForm.reviewerResult = res.reviewerResult
        this.assetRetirementForm.approverResult = res.approverResult
        this.assetRetirementForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAssetRetirementList(this.searchForm).then(res => {
        this.assetRetirementPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAssetRetirementList(this.searchForm).then(res => {
        this.assetRetirementPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAssetRetirementList(this.searchForm).then(res => {
        this.assetRetirementPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['ASSET_RETIREMENT_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['ASSET_RETIREMENT_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
